import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import SidebarLinks from "../../components/SidebarLinks"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function PaulFTecklenburg() {
  return (
    <Layout>
      <GatsbySeo
        title="Paul F. Tecklenburg | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Paul F. Tecklenburg is a native of Charleston, South Carolina and
            has practiced law since 1988. He concentrates on transportation and civil litigation, as well as acting as corporate counsel for international and local
            businesses."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/attorneys/paul-f-tecklenburg/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <SidebarLinks />
        </section>
        <section className={styles.content}>
          <h1>Paul F. Tecklenburg</h1>
          <StaticImage
            src="../../images/attorneys/paul-f-tecklenburg.jpg"
            className={styles.attorneyImage}
            width={300}
            alt="Paul F. Tecklenburg"
          />
          <p>
            Paul F. Tecklenburg is a native of Charleston, South Carolina and
            has practiced law since 1988. He concentrates on{" "}
            <Link to="/practice-areas/south-carolina-transportation-law">
              transportation
            </Link>{" "}
            and{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              civil litigation
            </Link>
            , as well as acting as corporate counsel for international and local
            businesses. He has litigated over such issues as personal injuries,
            wrongful death, environmental releases, construction claims,
            collections, products liability, cargo damage, business torts and
            insurance disputes. In addition, Mr. Tecklenburg has represented
            numerous clients in business matters such as real property, risk
            management, asset protection, corporate organization, vessel
            documentation and vessel foreclosures.
          </p>
          <p>
            Mr. Tecklenburg graduated from the Catholic University of America
            and the University Of South Carolina School Of Law. While in
            Washington, DC, he worked for Senator “Fritz” Hollings for three
            years. Mr. Tecklenburg also received a Master of Laws in Admiralty
            from Tulane University. While attending Tulane University, he was a
            staff attorney for the Port of New Orleans and responsible for
            drafting its tariff. After his own education was perfected, Mr.
            Tecklenburg returned to school as a lecturer on transportation
            legislation at the College of Charleston. He has also given
            presentations at seminars sponsored by the South Carolina Bar
            Association, Southeastern Admiralty Law Institute, South Carolina
            World Trade Institute and U.S. Department of Commerce.
          </p>
          <p>
            Mr. Tecklenburg has been awarded the “AV Preeminent” rating from
            Martindale Hubbell Peer Review Ratings. The AV Preeminent
            designation signifies he has been rated by fellow attorneys as
            having the highest legal abilities and ethical standards, and is
            awarded to less than 5% of all attorneys across the United States.
          </p>
          <h2>Bar Admissions:</h2>
          <ul>
            <li>
              <h3>Court of International Trade</h3>
            </li>
            <li>
              <h3>United States Court of Appeals 4th Circuit</h3>
            </li>
            <li>
              <h3>United States District Court of South Carolina</h3>
            </li>
            <li>
              <h3>State of South Carolina</h3>
            </li>
          </ul>
          <h2>Education:</h2>
          <ul>
            <li>
              <h3>Catholic University of America</h3>
            </li>
            <li>
              <h3>South Carolina School of Law – Juris Doctor</h3>
            </li>
            <li>
              <h3>Tulane University – Masters in Admiralty</h3>
            </li>
          </ul>
          <h2>Professional Associations and Memberships:</h2>
          <ul>
            <li>
              <h3>
                <a href="https://www.charlestoncountybar.org/">
                  Charleston County Bar Association
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.scbar.org/">
                  South Carolina Bar Association
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.seali.com/">
                  Southeastern Admiralty Law Institute
                </a>{" "}
                – Member and former Port Director
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://mlaus.org/">
                  Maritime Law Association of the United States
                </a>{" "}
                – Proctor
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.nartc.org/">
                  National Association of Railroad Trial Counsel
                </a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.wtca.org/world-trade-center-charleston">
                  South Carolina World Trade Center - Charleston
                </a>{" "}
                – Former Chairman of the Board
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.propellerclubchs.com/">
                  Propeller Club of the U.S. – Port of Charleston
                </a>{" "}
                – Former President
              </h3>
            </li>
          </ul>
          <p className={styles.attorneyFooter}>
            <Link to="/contact">Contact</Link>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="/Paul-F-Tecklenburg.vcf" download="Paul-F-Tecklenburg.vcf">
              Download VCard
            </a>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="https://www.linkedin.com/in/paul-tecklenburg-04a82bb/">
              View LinkedIn Profile
            </a>
          </p>
        </section>
      </section>
    </Layout>
  )
}
